<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <h1 class="static__page__title">{{title}}</h1>
      <div class="itemBox no-ma-top">
        <h2 class="boxTitle">運営会社</h2>
        <dl class="item__detail--spec--table">
          <dt>サイト名</dt>
          <dd>TOKYOWAVE</dd>
          <dt>運営会社</dt>
          <dd>ストリートストア株式会社（STREET STORE Inc.）</dd>
          <dt>設立</dt>
          <dd>2011年7月1日</dd>
          <dt>お問い合わせ</dt>
          <dd>info ＠ streetstore.co.jp</dd>
        </dl>
      </div>
      <div class="itemBox no-ma-top">
        <h2 class="boxTitle">著作権について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            「TOKYOWAVE（以下、当サイト）」に掲載されている情報、写真、イラストなどに関する著作権は、ストリートストア株式会社または各情報提供者に帰属します。
          </p>
          <p>
           当サイトを利用するユーザー（以下、ユーザー）は、これら（一部あるいは全部を問わず）を、複製、公開送信、頒布、譲渡、貸与、翻訳、翻案、使用許諾、転載、再用利用しないことに同意するものとします。
          </p>
        </div>
        <h2 class="boxTitle">二次利用について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            ユーザーが当サイトの掲載情報や画面を転載して利用する場合は、弊社の使用許諾が必要です。
          </p>
        </div>
        <h2 class="boxTitle">広告の表示について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            当サイトでは、提携各社から提供される広告を表示しています。これらの表示される広告を選択したり、非表示にすることは出来ません。万が一、不快な広告が連続して表示されていた場合は、info ＠ streetstore.co.jpまでお問い合わせください。
          </p>
        </div>
        <h2 class="boxTitle">商品の購入、サービスの契約について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            当サイトに掲載されている広告先での購入、契約等については、当サイトは一切に責任を負いません。ご了承の上、購入・契約をして頂きますようお願い致します。個々の契約の詳細については、各購入・契約先にご連絡して頂きますようお願い致します。
          </p>
        </div>
        <h2 class="boxTitle">免責事項</h2>
        <div class="itemBoxInner static__txt">
          <p>
            当サイトのページ内容は、配信日より期間が経過している場合など、状況により現在の情報とは異なる可能性があります。
          </p>
          <p>
            掲載内容については、十分に注意をした上で掲載をしておりますが、万が一、損害やトラブルが生じた場合は責任を負いかねますので、内容を御確認のうえ、ご自身の判断のもとでご利用ください。
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";

export default {
  data() {
    return {
      HeadSetting: {
        Left: 'backBtn',
        Right: 'mypage',
        Center: 'search'
      },
      title: 'TOKYOWAVEについて',
      description: 'TOKYOWAVEのサイト概要ページ。当サイトの運営者情報や、利用するにあたっての著作権、二次利用、広告の表示、免責事項などについて説明しています。'
    };
  },
  components: {
    Head,
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
}
</script>

<style scoped>
.static__page__title{
  text-align: center;
  margin: 32px 0;
}

.item__detail--spec--table dt{
  width: 30%;
}

.static__txt{
  font-size: .85rem;
}
</style>
